import { useEffect } from 'react'
import { useUser } from 'components/UserContext'
import firebase from 'utils/isomorphic-firebase'

export default function Analytics() {
  const user = useUser()
  useEffect(() => {
    firebase.analytics().setUserId(user?.uid || null)
  }, [user])
  return null
}
