import firebase from 'utils/isomorphic-firebase'

export default async function fetchCollection({ collectionId }) {
  if (!collectionId) {
    throw new Error(
      `A collectionId(${collectionId}) is required to fetch a collection.`
    )
  }

  const ref = firebase
    .firestore()
    .collection(`collections/${collectionId}/entities`)

  const querySnapshot = await ref.get()

  if (querySnapshot.empty) {
    throw new Error(`Collection ${ref.path} is empty!`)
  }

  return querySnapshot.docs.reduce(
    (docs, doc) => ({ ...docs, [doc.id]: doc.data() }),
    {}
  )
}
