import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  card: {
    width: 400
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  }
}))

const SnackMessage = React.forwardRef((props, ref) => {
  const { message, children, ...rest } = props
  const classes = useStyles()
  const { closeSnackbar } = useSnackbar()
  const [expanded, setExpanded] = useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const handleDismiss = () => {
    closeSnackbar(props.id)
  }

  return (
    <Alert
      {...rest}
      ref={ref}
      action={
        <>
          {rest.action}
          {children && (
            <IconButton
              className={classnames(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              size="small"
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon fontSize="small" />
            </IconButton>
          )}

          <IconButton color="inherit" size="small" onClick={handleDismiss}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
      className={classes.card}
      onClose={handleDismiss}
    >
      <AlertTitle gutterBottom={false}>{message}</AlertTitle>
      {children && (
        <Collapse unmountOnExit in={expanded} timeout="auto">
          {children}
        </Collapse>
      )}
    </Alert>
  )
})

SnackMessage.propTypes = {
  id: PropTypes.number.isRequired
}

export default SnackMessage
