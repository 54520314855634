import React, { useMemo } from 'react'
import { useRouter } from 'next/router'
import useCollectionTranslations from 'hooks/useCollectionTranslations'
import { Breadcrumbs } from '@material-ui/core'
import Link from 'components/Link'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { take } from 'ramda'
import titleCase from 'title-case'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(1.2)
  }
}))

// export default function AppBreadcrumbs(props) {
//   const { pageProps, PageComponent } = props
//   console.log(useRouter())
//   const classes = useStyles()
//   const t = useCollectionTranslations()
//   const breadcrumbs = useMemo(
//     () => PageComponent.getBreadcrumbs?.({ ...pageProps, t }),
//     [PageComponent.getBreadcrumbs, pageProps, t]
//   )

//   if (!breadcrumbs) return null

//   return (
//     <Breadcrumbs
//       aria-label="breadcrumb"
//       classes={{ root: classes.breadcrumbs }}
//       separator={<NavigateNextIcon fontSize="small" />}
//     >
//       <Link color="inherit" href="/dashboard">
//         <FormattedMessage key="dashboard" defaultMessage="Dashboard" />
//       </Link>
//       {breadcrumbs.map((breadcrumb) => (
//         <Link
//           as={breadcrumb.as}
//           color={breadcrumb.active ? 'textPrimary' : 'inherit'}
//           href={breadcrumb.href}
//         >
//           {breadcrumb.label}
//         </Link>
//       ))}
//     </Breadcrumbs>
//   )
// }

const FILTER_SEGMENTS = ['collections', 'dashboard']
const LABEL_SEGMENTS = {
  '[collectionId]': (t, query) => t.name(query.collectionId),
  '[entityId]': (t, query) =>
    `${query.entityId.slice(0, 3)}…${query.entityId.slice(-3)}`
}

export default function AppBreadcrumbs() {
  const { route, asPath, query } = useRouter()
  const classes = useStyles()
  const t = useCollectionTranslations()
  const breadcrumbs = useMemo(() => {
    const routeSegments = route.replace(/^\//, '').split('/')
    const asPathSegments = asPath.replace(/^\//, '').split('/')

    return routeSegments
      .map((segment, idx) => {
        if (FILTER_SEGMENTS.includes(segment)) {
          return null
        }
        const label = LABEL_SEGMENTS[segment]?.(t, query) || (
          <FormattedMessage
            defaultMessage="{tc_segment}"
            values={{ tc_segment: titleCase(segment), segment }}
          />
        )
        return {
          label,
          as: `/${take(idx + 1, asPathSegments).join('/')}`,
          href: `/${take(idx + 1, routeSegments).join('/')}`,
          active: idx === routeSegments.length - 1
        }
      })
      .filter(Boolean)
  }, [asPath, query, route, t])

  if (breadcrumbs.length <= 0) return null

  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      classes={{ root: classes.breadcrumbs }}
      separator={<NavigateNextIcon fontSize="small" />}
    >
      <Link color="inherit" href="/dashboard">
        <FormattedMessage defaultMessage="Home" />
      </Link>
      {breadcrumbs.map((breadcrumb) => (
        <Link
          key={breadcrumb.href}
          as={breadcrumb.as}
          color={breadcrumb.active ? 'textPrimary' : 'inherit'}
          href={breadcrumb.href}
        >
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  )
}
