import config from 'config'
import DownloadPdfButton from 'components/DownloadPdfButton'
import {
  AppBar,
  Chip,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined'
import AccountCircle from '@material-ui/icons/AccountCircleOutlined'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import DeveloperModeIcon from '@material-ui/icons/DeveloperModeOutlined'
import HelpIcon from '@material-ui/icons/HelpOutline'
import InboxIcon from '@material-ui/icons/InboxOutlined'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import SearchIcon from '@material-ui/icons/SearchOutlined'
import TranslateIcon from '@material-ui/icons/TranslateOutlined'
import { makeStyles, useTheme } from '@material-ui/styles'
import firebase from 'utils/isomorphic-firebase'
import { useUser as useUserDeprecated } from '@sb-konzept/firebase-hooks'
import Box from 'components/Box'
import Tutorial from 'components/Tutorial'
import useIsRole, { useMockedRole } from 'hooks/useIsRole'
import React, { useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import Link from 'components/Link'
import titleCase from 'title-case'
import useCollectionConfig from 'hooks/useCollectionConfig'
import { useApp } from 'components/AppContext'
import { useRouter } from 'next/router'
import { useUser } from 'components/UserContext'
import useCollectionTranslations from 'hooks/useCollectionTranslations'
import { useRoleNames } from 'hooks/useRoles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    marginLeft: ({ showDrawer }) => showDrawer && theme.drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: ({ showDrawer }) =>
        showDrawer && `calc(100% - ${theme.drawerWidth}px)`
    },
    '@media print': {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  toolbar: { ...theme.mixins.toolbar, '@media print': { display: 'none' } },
  drawerPaper: {
    display: 'block',
    width: theme.drawerWidth
  },
  title: { flexGrow: 1 },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  drawerLinkActive: {
    '& $drawerLinkIconRoot': {
      color: theme.palette.primary.main
    },
    '& $drawerLinkTextRoot': {
      color: theme.palette.primary.main,
      fontWeight: 600
    }
  },
  drawerLinkTextRoot: {},
  drawerLinkIconRoot: {
    color: theme.palette.common.black
  },
  chip: {
    color: '#ffffff',
    border: '1px solid #ffffff'
  },
  roleSelect: {
    color: '#ffffff'
  },
  roleSelectIcon: {
    color: '#ffffff'
  }
}))

export function AppBarSpacer() {
  const classes = useStyles()

  return <div className={classes.toolbar} />
}

function DrawerLink(props) {
  const { title, icon, ...rest } = props
  const classes = useStyles()

  return (
    <ListItem
      button
      naked
      partial
      activeClassName={classes.drawerLinkActive}
      component={Link}
      {...rest}
    >
      {icon && (
        <ListItemIcon classes={{ root: classes.drawerLinkIconRoot }}>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText
        classes={{ primary: classes.drawerLinkTextRoot }}
        primary={title}
      />
    </ListItem>
  )
}

function Logo() {
  return (
    <Link naked href="/">
      <Box component="img" p={1} src={config.logo} width="100%" />
    </Link>
  )
}

function useDrawerItems() {
  const [isRole] = useIsRole()
  const user = useUser()
  const [collectionConfigs] = useCollectionConfig()
  const t = useCollectionTranslations()

  return useMemo(() => {
    const sections = [
      [
        {
          title: <FormattedMessage defaultMessage="Dashboard" />,
          href: '/dashboard',
          as: `/dashboard`,
          icon: <DashboardIcon />
        }
      ],
      [
        isRole('superAdmin') && {
          title: <FormattedMessage defaultMessage="Development" />,
          href: '/develop',
          icon: <DeveloperModeIcon />
        },
        isRole(['globalRep', 'superAdmin']) && {
          title: <FormattedMessage defaultMessage="Translations" />,
          href: '/translations',
          icon: <TranslateIcon />
        }
      ],
      collectionConfigs &&
        Object.entries(collectionConfigs).map(
          ([id, c]) =>
            isRole(c.permissions?.viewDrawerItem) && {
              title: t.name(id),
              href: `/collections/[collectionId]`,
              as: `/collections/${id}`
            }
        ),
      [
        {
          title: <FormattedMessage defaultMessage="Inbox" />,
          href: '/inbox',
          icon: <InboxIcon />
        },
        user && {
          title: <FormattedMessage defaultMessage="Profile" />,
          href: '/profile',
          icon: <AccountBoxIcon />
        }
      ]
    ]
    return sections
      .filter(Boolean)
      .map((section) => section.filter(Boolean))
      .filter((section) => section.length > 0)
  }, [isRole, collectionConfigs, user, t])
}

function DrawerContent() {
  const drawerItems = useDrawerItems()

  return (
    <>
      <Logo />
      {drawerItems.map((drawerSection, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={idx}>
          <Divider />
          <List>
            {drawerSection.map((drawerItem, jdx) => (
              // eslint-disable-next-line react/no-array-index-key
              <DrawerLink key={jdx} {...drawerItem} />
            ))}
          </List>
        </React.Fragment>
      ))}
      <Box color="grey.500" mt="auto" padding={1}>
        <Typography align="center" color="inherit" variant="caption">
          {process.env.GIT_VERSION}
        </Typography>
      </Box>
    </>
  )
}

function Navigation(props) {
  const { showDrawer, appBarRef } = props
  // const currentRoute = useCurrentRoute()
  const classes = useStyles({ showDrawer })
  const theme = useTheme()
  const user = useUser()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { title } = useApp()
  const [mockedRole, setMockedRole] = useMockedRole()
  const roles = useRoleNames()
  const open = Boolean(anchorEl)
  const router = useRouter()
  const t = useCollectionTranslations()
  // TODO: implement routeTutorial with next
  // const routeTutorial = currentRoute.data.tutorial || ''
  const routeTutorial = ''

  function handleMenu(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function handleLogout() {
    handleClose()
    firebase.auth().signOut()
  }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen)
  }

  return (
    <>
      {showDrawer && (
        <Box
          component="nav"
          flexShrink={{ md: 0 }}
          width={{ md: theme.drawerWidth }}
        >
          <>
            <Hidden mdUp implementation="css">
              <Drawer
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                open={mobileOpen}
                variant="temporary"
                onClose={handleDrawerToggle}
              >
                <DrawerContent />
              </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
              <Drawer
                open
                classes={{
                  paper: classes.drawerPaper
                }}
                variant="permanent"
              >
                <DrawerContent />
              </Drawer>
            </Hidden>
          </>
        </Box>
      )}
      <AppBar
        ref={appBarRef}
        classes={{ root: classes.appBar }}
        position="fixed"
      >
        <Toolbar>
          {showDrawer && (
            <IconButton
              aria-label="Open drawer"
              className={classes.menuButton}
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Tutorial name={routeTutorial}>
            <Typography
              noWrap
              classes={{ root: classes.title }}
              color="inherit"
              variant="h6"
            >
              {/* // TODO: implement this {currentRoute.title} */}
              {title}
            </Typography>
          </Tutorial>
          {user && (
            <div>
              <Hidden smDown implementation="js">
                {user.role === 'superAdmin' && (
                  <Select
                    classes={{
                      root: classes.roleSelect,
                      icon: classes.roleSelectIcon
                    }}
                    value={mockedRole}
                    onChange={(evt) => setMockedRole(evt.target.value)}
                  >
                    <MenuItem value="actual">Actual ({user.role})</MenuItem>
                    {roles.map((role) => (
                      <MenuItem key={role} value={role}>
                        {role}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                <Chip
                  classes={{ root: classes.chip }}
                  label={t.value('roles', `roleName`, user.role)}
                  size="small"
                  variant="outlined"
                />
              </Hidden>
              <IconButton
                aria-haspopup="true"
                aria-owns={open ? 'menu-appbar' : undefined}
                color="inherit"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                id="menu-appbar"
                open={open}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                onClose={handleClose}
              >
                <MenuItem naked component={Link} href="/profile">
                  <FormattedMessage defaultMessage="Profile" />
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <FormattedMessage defaultMessage="Logout" />
                </MenuItem>
              </Menu>
              {router.query.entityId && router.query.collectionId && (
                <DownloadPdfButton
                  collectionId={router.query.collectionId}
                  entityId={router.query.entityId}
                />
              )}
            </div>
          )}
        </Toolbar>
      </AppBar>
    </>
  )
}

export default Navigation
