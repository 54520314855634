import { CircularProgress, IconButton } from '@material-ui/core'
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined'
import Box from 'components/Box'
import FileSaver from 'file-saver'
import React, { useCallback, useState } from 'react'

function getTimeZone(defaultTz = 'Europe/Berlin') {
  try {
    if (typeof Intl !== 'undefined') {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    return defaultTz
  } catch (e) {
    return defaultTz
  }
}

export default function DownloadPdfButton(props) {
  const { entityId, collectionId } = props
  const [isDownloading, setIsDownloading] = useState(false)

  const download = useCallback(async () => {
    setIsDownloading(true)
    const response = await fetch(
      `/api/pdf?i=${entityId}&c=${collectionId}&tz=${getTimeZone()}`
    )
    const blob = await response.blob()
    FileSaver.saveAs(blob, `${collectionId}-${entityId}.pdf`)

    setIsDownloading(false)
  }, [collectionId, entityId])

  return (
    <Box display="inline-block" position="relative">
      <IconButton color="inherit" disabled={isDownloading} onClick={download}>
        <GetAppOutlinedIcon />
      </IconButton>

      {isDownloading && (
        <Box
          clone
          color="white"
          css="opacity: 0.8;"
          left="50%"
          marginLeft="-22px"
          marginTop="-22px"
          position="absolute"
          top="50%"
        >
          <CircularProgress color="inherit" size={44} />
        </Box>
      )}
    </Box>
  )
}
