/* eslint-disable no-multi-assign */
import React, { useEffect } from 'react'
import { getInitialUser, UserProvider, useUser } from 'components/UserContext'
import Router, { useRouter } from 'next/router'
import NextApp from 'next/app'
import Head from 'next/head'
import qs from 'qs'

import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { createMuiTheme } from '@material-ui/core/styles'
import LocaleProvider from 'components/LocaleProvider'
import { MockedRoleProvider } from 'hooks/useIsRole'
import {
  UserProvider as UserProviderDeprecated,
  FirebaseProvider
} from '@sb-konzept/firebase-hooks'
import Layout from 'components/Layout'
import Analytics from 'components/Analytics'
import firebase, { serializer } from 'utils/isomorphic-firebase'
import config from 'config'
import fetchCollectionConfig from 'utils/fetchCollectionConfig'
import fetchLocale from 'utils/firebase/fetchLocale'
import { AppProvider } from 'components/AppContext'
import Sentry from 'components/Sentry'
import fetchEntity from 'utils/fetchEntity'
import { setSentryUser } from 'utils/sentry'
import fetchCollection from 'utils/fetchCollection'
import 'assets/css/dev-router-push-workaround.css' // https://github.com/zeit/next.js/issues/5598
import sessionMiddleware from 'utils/middleware/session'
import getLanguage from 'utils/getLanguage'

const theme = createMuiTheme(config.muiTheme)

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

const UNAUTHENTICATED_ROUTES = [
  '/login',
  '/reset-password',
  '/auth/action',
  '/legal/imprint',
  '/legal/terms',
  '/legal/data-policy',
  '/404',
  '/humbaur/appointment-reservation/[entityId]'
]

function UserGuard(props) {
  const { children } = props
  const user = useUser()
  const router = useRouter()

  useEffect(() => {
    if (!user && !UNAUTHENTICATED_ROUTES.includes(router.route))
      router.replace('/login')
  }, [router, user])

  if (!user && !UNAUTHENTICATED_ROUTES.includes(router.route)) return null

  return children
}

function App(props) {
  const {
    Component,
    user,
    collectionConfig,
    title = config.projectName,
    firebaseUser,
    locale,
    roles,
    pageProps,
    showLayout
  } = props

  const PageLayout = showLayout ? Layout : React.Fragment

  // Workaround for https://github.com/zeit/next.js/issues/8592
  const { err } = props
  const modifiedPageProps = { ...pageProps, err }

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>
      <FirebaseProvider app={firebase}>
        <UserProvider initialUser={user}>
          <AppProvider
            collectionConfig={collectionConfig}
            firebaseUser={firebaseUser}
            locale={locale}
            roles={roles}
            title={title}
          >
            <UserProviderDeprecated>
              <MockedRoleProvider>
                <LocaleProvider>
                  <ThemeProvider theme={theme}>
                    <Analytics />
                    <CssBaseline />
                    <SnackbarProvider>
                      <Sentry>
                        <PageLayout>
                          <UserGuard>
                            <Component {...modifiedPageProps} />
                          </UserGuard>
                        </PageLayout>
                      </Sentry>
                    </SnackbarProvider>
                  </ThemeProvider>
                </LocaleProvider>
              </MockedRoleProvider>
            </UserProviderDeprecated>
          </AppProvider>
        </UserProvider>
      </FirebaseProvider>
    </>
  )
}

async function enhanceContext(appContext) {
  const { ctx, Component } = appContext
  const { title } = Component
  const serverData =
    typeof window === 'undefined' ? {} : window.__NEXT_DATA__.props

  // allows deep objects in the query
  ctx.query = qs.parse(qs.stringify(ctx.query))

  const props = {}

  async function addToContext(key, getValue, { alwaysGetValue = false } = {}) {
    // console.time(key)
    props[key] = ctx[key] = alwaysGetValue
      ? await getValue()
      : serverData[key] || (await getValue())
    // console.timeEnd(key)
  }

  await addToContext('user', () => getInitialUser(ctx), {
    alwaysGetValue: true
  })

  await addToContext('firebaseUser', async () =>
    props.user
      ? serializer.serialize(
          await fetchEntity({
            collectionId: 'users',
            entityId: props.user.uid
          })
        )
      : null
  )

  await addToContext('roles', async () =>
    serializer.serialize(await fetchCollection({ collectionId: 'roles' }))
  )

  const locale = props.firebaseUser?.language || getLanguage(ctx)

  // TODO: user the Accept-Language header and navigator.language to get the fallback language
  // https://www.npmjs.com/package/accept-language-parser
  await addToContext('locale', () => fetchLocale(locale))

  await addToContext('collectionConfig', async () =>
    serializer.serialize(await fetchCollectionConfig({ locale }))
  )

  await addToContext('title', async () =>
    typeof title === 'function' ? await title(ctx) : undefined
  )

  return props
}

export default class EnhancedApp extends NextApp {
  static async getInitialProps(appContext) {
    const { ctx, Component } = appContext
    const { redirect, showLayout = true } = Component

    if (typeof window === 'undefined') {
      await sessionMiddleware(ctx.req, ctx.res)
    }

    const props = await enhanceContext(appContext)

    props.showLayout = ctx.showLayout = showLayout

    const redirectTo =
      typeof redirect === 'function' ? await redirect(ctx) : null

    if (redirectTo) {
      if (ctx.res) {
        ctx.res.writeHead(302, {
          Location: redirectTo
        })
        ctx.res.end()
      } else {
        Router.push(redirectTo)
      }
    }

    const appProps = await NextApp.getInitialProps(appContext)

    setSentryUser(props.user)

    return {
      ...appProps,
      ...props
    }
  }

  render() {
    return <App {...this.props} />
  }
}
