import { prop } from 'ramda'
import { useMemo } from 'react'
import { useApp } from 'components/AppContext'
import { useUser } from 'components/UserContext'

export default function useRoles(role) {
  const user = useUser()
  const { roles } = useApp()
  const rolesByName = useMemo(
    () =>
      Object.values(roles).reduce(
        (acc, val) => ({ ...acc, [val.roleName]: val }),
        {}
      ),
    [roles]
  )
  if (role === true) {
    return rolesByName[user.role]
  }
  if (role) {
    return rolesByName[role]
  }
  return rolesByName
}

export function useRoleNames() {
  const roles = useRoles()
  return useMemo(() => Object.keys(roles), [roles])
}
