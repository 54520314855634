import DateFnsUtils from '@date-io/date-fns'
import flatten from 'flat'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useMemo, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { useApp } from 'components/AppContext'
import { defaultErrorHandler } from 'react-intl/dist/utils'

// TODO: eventually we should remove this and solve the issue with full-icu
// which currently doesnt work because vercel doesnt support it
if (typeof window === 'undefined') {
  const areIntlLocalesSupported = require('intl-locales-supported').default

  const localesMyAppSupports = ['en', 'de']

  if (global.Intl) {
    // Determine if the built-in `Intl` has the locale data we need.
    if (!areIntlLocalesSupported(localesMyAppSupports)) {
      // `Intl` exists, but it doesn't have the data we need, so load the
      // polyfill and patch the constructors we need with the polyfill's.
      const IntlPolyfill = require('intl')
      Intl.NumberFormat = IntlPolyfill.NumberFormat
      Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    }
  } else {
    // No `Intl`, so use and load the polyfill.
    global.Intl = require('intl')
  }
}

const localeMap = {
  de: import('date-fns/locale/de'),
  fi: import('date-fns/locale/fi'),
  fr: import('date-fns/locale/fr'),
  sv: import('date-fns/locale/sv')
}

function useLocaleData(locale) {
  const [loading, setLoading] = useState(true)
  const [dateLocaleData, setDateLocaleData] = useState()
  useEffect(() => {
    async function fetchLocaleData() {
      const dld = await (localeMap[locale] || import('date-fns/locale/en-GB'))
      setDateLocaleData(dld.default)
      setLoading(false)
    }
    fetchLocaleData()
    return () => setLoading(true)
  }, [locale])

  return { dateLocaleData, loading }
}

export function useLanguage(user) {
  const language = useMemo(
    () =>
      (
        user?.language ||
        (typeof navigator !== 'undefined' ? navigator.language : 'en')
      ).split(/[-_]/)[0],
    [user]
  )

  return language
}

function errorHandler(error) {
  if (process.env.NODE_ENV !== 'production') {
    if (error.code === 'MISSING_TRANSLATION') {
      if (typeof window !== 'undefined') {
        window.__missingTranslations = window.__missingTranslations || []
        window.__missingTranslations.push(error)
      }
    } else console.error(error)
  }
}

export default function LocaleProvider(props) {
  const { children } = props
  const { locale, firebaseUser } = useApp()
  const language = useLanguage(firebaseUser)
  const { dateLocaleData } = useLocaleData(language)

  const messages = useMemo(() => flatten(locale || {}), [locale])

  return (
    <MuiPickersUtilsProvider locale={dateLocaleData} utils={DateFnsUtils}>
      <IntlProvider
        key={language}
        locale={language}
        messages={messages}
        onError={errorHandler}
      >
        {children}
      </IntlProvider>
    </MuiPickersUtilsProvider>
  )
}
