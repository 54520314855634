import { useEffect } from 'react'
import NProgress from 'nprogress'
import Router from 'next/router'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      '#nprogress': {
        pointerEvents: 'none'
      },
      '.nprogress-custom-parent': {
        overflow: 'hidden',
        position: 'relative'
      }
    },
    bar: {
      background: theme.palette.secondary.main,
      position: 'fixed',
      zIndex: theme.zIndex.tooltip + 100,
      top: 0,
      left: 0,
      width: '100%',
      height: '2px',
      '.nprogress-custom-parent #nprogress &': {
        position: 'absolute'
      }
    },
    peg: {
      display: 'block',
      position: 'absolute',
      right: '0px',
      width: '100px',
      height: '100%',
      boxShadow: `0 0 10px ${theme.palette.secondary.main}, 0 0 5px ${theme.palette.secondary.main}`,
      opacity: 1,
      transform: 'rotate(3deg) translate(0px, -4px)'
    },
    spinner: {
      display: ({ spinner }) => (spinner ? 'block' : 'none'),
      position: 'fixed',
      zIndex: theme.zIndex.tooltip + 100,
      top: '15px',
      right: '15px',
      '.nprogress-custom-parent #nprogress &': {
        position: 'absolute'
      }
    },
    spinnerIcon: {
      width: '18px',
      height: '18px',
      boxSizing: 'border-box',
      border: 'solid 2px transparent',
      borderTopColor: theme.palette.secondary.main,
      borderLeftColor: theme.palette.secondary.main,
      borderRadius: '50%',
      animation: '$spinner 400ms linear infinite'
    },
    '@keyframes spinner': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '100%': {
        transform: 'rotate(360deg)'
      }
    }
  }),
  []
)

function NProgressContainer(props) {
  const { showAfterMs = 300, spinner = false, options = {} } = props

  const classes = useStyles({ spinner })

  useEffect(() => {
    let timer = null

    const routeChangeStart = () => {
      clearTimeout(timer)
      timer = setTimeout(NProgress.start, showAfterMs)
    }

    const routeChangeEnd = () => {
      clearTimeout(timer)
      NProgress.done()
    }

    NProgress.configure({
      ...options,
      template: `
<div class="${classes.bar}" role="bar">
  <div class="${classes.peg}"></div>
</div>
<div class="${classes.spinner}" role="spinner">
  <div class="${classes.spinnerIcon}"></div>
</div>
`
    })

    Router.events.on('routeChangeStart', routeChangeStart)
    Router.events.on('routeChangeComplete', routeChangeEnd)
    Router.events.on('routeChangeError', routeChangeEnd)

    return () => {
      clearTimeout(timer)
      Router.events.off('routeChangeStart', routeChangeStart)
      Router.events.off('routeChangeComplete', routeChangeEnd)
      Router.events.off('routeChangeError', routeChangeEnd)
    }
  }, [
    classes.bar,
    classes.peg,
    classes.spinner,
    classes.spinnerIcon,
    options,
    showAfterMs
  ])

  return null
}

export default NProgressContainer
