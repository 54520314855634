import config from 'config'
import * as Sentry from '@sentry/node'
import * as SentryIntegrations from '@sentry/integrations'

const sentryOptions = {
  dsn: config.sentryDsn,
  release: config.buildId,
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  environment: config.sentryEnvironment
}

// When we're developing locally
if (process.env.NODE_ENV !== 'production') {
  // Don't actually send the errors to Sentry
  sentryOptions.beforeSend = () => null

  // Instead, dump the errors to the console
  sentryOptions.integrations = [
    new SentryIntegrations.Debug({
      // Trigger DevTools debugger instead of using console.log
      debugger: false
    })
  ]
} else if (typeof window !== 'undefined') {
  sentryOptions.beforeSend = (event) => {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id })
    }
    return event
  }
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init(sentryOptions)
}

function setSentryUser(user) {
  if (!user) return
  Sentry.setUser({
    id: user.uid,
    username: user.name,
    email: user.email,
    auth_time: user.auth_time,
    email_verified: user.email_verified,
    role: user.role,
    server: user.server,
    aud: user.aud,
    firebase: user.firebase
  })
}

function findEventContext(args) {
  return args.find((arg) => arg.eventId)
}

function withSentry(fn) {
  if (process.env.NODE_ENV !== 'production') {
    return fn
  }
  return async function(...args) {
    Sentry.configureScope((scope) => {
      scope.setExtra('process.env', process.env)

      if (process.env.FUNCTION_TARGET) {
        scope.setExtra('isFunctions', true)
        scope.setExtra('functionTarget', process.env.FUNCTION_TARGET)
        scope.setExtra('eventContext', findEventContext(args))
      }
    })
    try {
      return await fn.apply(this, args)
    } catch (e) {
      Sentry.captureException(e)
      await Sentry.flush(2000)
      throw e
    }
  }
}

export { Sentry, setSentryUser, withSentry }
