import { useEffect, useState, useCallback } from 'react'

function getSize(el) {
  if (!el) {
    return {
      width: 0,
      height: 0
    }
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight
  }
}

function useComponentSize(ref) {
  const [ComponentSize, setComponentSize] = useState(
    getSize(ref ? ref.current : {})
  )

  const handleResize = useCallback(
    function handleResize() {
      if (ref.current) {
        setComponentSize(getSize(ref.current))
      }
    },
    [ref, setComponentSize]
  )

  useEffect(() => {
    if (!ref.current) {
      return () => {}
    }

    handleResize()

    if (typeof ResizeObserver === 'function') {
      const el = ref.current
      let resizeObserver = new ResizeObserver(() => {
        window.requestAnimationFrame(() => {
          handleResize()
        })
      })
      resizeObserver.observe(el)

      return () => {
        resizeObserver.disconnect(el)
        resizeObserver = null
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, ref])

  return ComponentSize
}

export default useComponentSize
