let parser
if (typeof window === 'undefined') {
  parser = require('accept-language-parser')
}

const DEFAULT_LANGUAGE = 'en'

export default function getLanguage(ctx) {
  if (ctx.req) {
    return parser.pick(['de', 'en'], ctx.req.headers['accept-language'])
  }

  return typeof navigator !== 'undefined'
    ? navigator.language?.split(/[-_]/)[0]
    : DEFAULT_LANGUAGE
}
