import { Sentry } from 'utils/sentry'
import React, { useEffect } from 'react'
import { CallApiError } from 'utils/callApi'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import SnackMessage from 'components/SnackMessage'
import { Typography } from '@material-ui/core'

function SentryGuard(props) {
  const { children } = props
  const { enqueueSnackbar } = useSnackbar()
  const intl = useIntl()

  useEffect(() => {
    Sentry.addGlobalEventProcessor((event, { originalException }) => {
      if (!event.exception) return event
      if (
        originalException instanceof CallApiError &&
        (originalException.code === 401 || originalException.code === 403)
      ) {
        enqueueSnackbar(
          intl.formatMessage(
            { defaultMessage: 'An error has occured. ({code} - {statusText})' },
            originalException
          ),
          {
            persist: true,
            content: (key, message) => (
              <SnackMessage id={key} message={message} severity="error">
                {Object.values(originalException.data).map((error) => (
                  <Typography>{error}</Typography>
                ))}
              </SnackMessage>
            )
          }
        )
        return null
      }
      return event
    })
  }, [enqueueSnackbar, intl])

  return children
}

export default SentryGuard
