import AppBar from 'components/AppBar'
import Box from 'components/Box'
import React, { useRef } from 'react'
import NProgress from 'components/NProgress'
import { useUser } from 'components/UserContext'
import AppBreadcrumbs from 'components/AppBreadcrumbs'
import useComponentSize from 'hooks/useComponentSize'
import { makeStyles } from '@material-ui/styles'

// TODO: implement 404 page

const useStyles = makeStyles({
  '@global': {
    '.sentry-error-embed-wrapper': {
      'z-index': '9001 !important;'
    }
  }
})

function Layout(props) {
  useStyles()
  const { children } = props
  const user = useUser()
  const appBarRef = useRef()
  const { height: _appBarHeight } = useComponentSize(appBarRef)
  const appBarHeight = _appBarHeight || 56

  return (
    <Box display="flex">
      <NProgress />

      <AppBar appBarRef={appBarRef} showDrawer={!!user} />

      <Box
        component="main"
        flexGrow={1}
        p={2}
        style={{
          overflowY: 'auto',
          minHeight: `calc(100vh - ${appBarHeight}px)`
        }}
        width="100%"
      >
        <Box style={{ height: appBarHeight }} />
        {!!user && <AppBreadcrumbs />}
        {children}
      </Box>
    </Box>
  )
}

export default Layout
