import firebase from 'utils/isomorphic-firebase'

export async function fetchEntityByPath(path) {
  const ref = firebase.firestore().doc(path)

  const snapshot = await ref.get()

  if (!snapshot.exists) {
    throw new Error(`Entity ${ref.path} doesnt exist!`)
  }

  return snapshot.data()
}

export default async function fetchEntity({ collectionId, entityId, path }) {
  if (!collectionId ) {
    throw new Error(
      `A collectionId(${collectionId}) are required to fetch entities.`
    )
  }

  if (!entityId) {
    const p = [`collections`, `${collectionId}`, `entities`, path].filter(
      Boolean
    )
    const ref = firebase.firestore().collection(p.join('/'))

    const querySnapshot = await ref.get()

    return querySnapshot.docs.reduce(
      (docs, doc) => ({ ...docs, [doc.id]: doc.data() }),
      {}
    )
  }

  const p = [
    `collections`,
    `${collectionId}`,
    `entities`,
    entityId,
    path
  ].filter(Boolean)
  return fetchEntityByPath(p.join('/'))
}
